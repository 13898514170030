const Text = {
  headers: {
    aboutMe: "About Me:",
    skills: "Skills:",
    experience: "Experience:",
    projects: "Projects:",
    contactMe: "Contact Me:",
    frontend: "Front-End:",
    backend: "Back-End:",
    misc: "Misc:",
  },
  AboutMeText:
    "My name is Yaniv Weinshtein. I'm a Practical Engineer in Electronics and a full-stack developer specializing in the MERN stack. I got exposed to coding in some of my practical engineering courses, and immediately I fell in love with the idea of creating solutions to day-to-day problems using only lines of code! I continued to code for fun for a while, and now I'm doing it professionally as my main career path!",
  AlwaysDiscovering:
    "A part of being a software developer is being able to keep up with the latest technologies. and as technology evolves, I constantly find myself learning new things, exposing myself to new tools and technologies, and growing my skills and knowledge.",
  yearsOfExperience: `
  Over the last 2 years, I've been involved in a variety of projects of different sizes. I've worked closely with other engineers, designers, QA, and project managers. Each project has been a chance to learn new technologies, share knowledge with colleagues, and get better at developing high-quality software as part of a team.`,
  ContactMe: "If you came all this way, you probably liked what you've seen.",
  ContactMeBold: "if so, tell me about it!",
  MessageSent: "Thank you for your response!",
  ErrorMessage: "Something went wrong...",
} as const;

export default Text;
